import '../styles/global.scss';
import '../styles/pages/privacy.scss';

import {HeadFC, Link} from "gatsby";

import {
    BigLogoImage
} from "../images";
import React, {useState} from 'react'
import MainFooter from '../components/MainFooter';

const Privacy = () => {

    return (
        <>
            <div className="DocPage">
                <div className="DocPageLogo">
                    <Link to="/">
                        <img src={BigLogoImage} width={200} alt=""/>
                    </Link>
                </div>
                <div className="DocPageWrapper">
                    <h2>“TERMS OF USE”</h2>
                    <p>TERMS AND CONDITIONS FOR THE SUPPLY OF SERVICES (USE OF AEER PLATFORM)</p><br />

                    <p><b>Our contacts:</b> e-mail: <a href="mailto:Ceo@aeer-platform.com">Ceo@aeer-platform.com</a> <br /> postal address: 8 The Green Ste A Dover, DE 19901, United States of America</p>

                    <h3>1. GENERAL PROVISIONS</h3>
                    1.1. Edition effective since: 23 June 2022 <br />
                    1.2. DISCLAIMER: <br />
                    Thank you for selecting AEER Platform. These Terms of Service (also “Terms”) describe our commitments to you, and your
                    rights and responsibilities when using our Services. Please read them carefully and reach out to us if you have any
                    questions. If you don’t agree to these Terms, please don’t use our services.
                    We hope that our Services will be helpful for you. We offer a digital solution for self-development, including skills seminars
                    and tools for personal finance and productivity. We reserve our right to develop new products to meet Customers’ needs
                    and provide benefits from digital solutions.
                    We may amend these Terms from time to time, and you are highly recommended to regularly review them.
                    By continuing to use website/mobile app/our service, you agree that you will not use any of the information obtained
                    from the software, including digital solutions and/or information databases, for the purposes of making any financial or
                    formal decisions.<br />
                    1.3. DEFINITIONS <br />
                    The following definitions and rules of interpretation apply in this agreement.
                    “We” or “we” (“our”, “us”, etc.) or “AEER” – (as defined below) adopted these terms of use, we operate the website
                    https://aeer-platform.com, offering you the “Software” (as defined below). We are AEER Platform Inc, a company
                    organized under the laws of Delaware, United States, with the following registered address: 8 The Green Ste A Dover, DE
                    19901, United States of America.
                    “You” - references in these Terms to “Customer”, “you” or “your” are references to individuals who use the Website,
                    Software and Service. If you are entering into this Agreement on behalf of a company, business or other legal entity, or
                    individual, you represent that you have the authority to bind such entity/individual and its affiliates to this Agreement, in
                    which case the terms “you” or “your” shall refer to such entity and its affiliates. If you do not have such authority, or if
                    you do not agree with this Agreement, you must not accept this Agreement and may not use the Services. The Website,
                    Software and Service are not intended for children and we do not knowingly collect personal data relating to children.
                    “Software” – is a software provided by AEER Platform Inc, specified in Subscription Plan.
                    “Service” – is a service for providing “Software” based on its Subscription Plan and other legally binding terms hereunder.
                    These Terms govern your access to and use of the products and services we provide through website (subscription
                    platform) at https://aeer-platform.com or mobile app.
                    “Subscription platform” (or “Platform”) - is a cloud-based software solution that allows AEER to sell subscriptions,
                    products, or services online, integrating with payment gateways to bill Customers on a recurring basis.
                    Website - website available at URL: https://aeer-platform.com/.
                    Mobile app – AEER mobile application that can be downloaded via Appstore or Google Play.
                    “Deliverables” - any output of the Services to be provided by the AEER to the Customer and any other documents,
                    products and materials provided by the AEER to the Customer in relation to the Services. <br />
                    1.4. OUR AGREEMENT WITH YOU <br />
                    Our contract. These Terms of Use (hereafter also - “Terms”) apply to the order by you and supply of Services by us to you.
                    By accessing or using any part of our Services, you agree to be bound by all of the Terms and all other operating rules,
                    policies, and other procedures that we may publish via the Services from time to time (collectively, the “Agreement”).
                    Changes. You also agree that we may automatically change, update, or add on to our Services, and this Agreement will
                    apply to any changes. AEER may introduce pop-up notification, email or Online Cabinet notice or Profile notice on such
                    change which may have immediate effect. The Customer confirms his/her awareness and consent to changed Terms by
                    continuing access to the user interface of the Software upon change of Terms of Use. By using the Service, Customer
                    accepts these terms and conditions in full. If Customer disagrees with these terms and conditions or any part of these
                    terms and conditions, Customer must not use this Service. If any change to this Agreement is not acceptable to you, your
                    only remedy is to stop using the Services.
                    Excluding any other terms. Any other terms that you seek to impose or incorporate, or which are implied by trade,
                    custom, practice or course of dealing, – are inapplicable; You also acknowledge that you have not relied on any statement,
                    promise or representation or assurance or warranty that is not set out in writing or online on the website. AEER reserves
                    all rights not expressly granted to you in this Agreement. Interpretation rules are defined below in Clause 12.4. <br />
                    <h3>2. COMMENCEMENT AND DURATION</h3> <br />
                    2.1. Free service. If Customer orders free trial – these Terms of Use are applicable once such free trial is granted; requesting
                    free trial shall mean that Customer fully accepts these Terms of Use. Free trial is limited in time; AEER may solely
                    determine time for free trial. <br />
                    2.2. Paid service. The Customer receives the service as long as Customer pays. Failure to provide the due payment leads to
                    suspending of the service but does not automatically terminate the Agreement. Agreement terminates automatically
                    after one year following the lapse of last paid period – “End date”. Early termination may be performed by mutual
                    agreement of the parties or under grounds for unilateral termination. <br />
                    <h3>3. AEER'S RESPONSIBILITIES</h3> <br />
                    3.1. AEER shall use reasonable endeavors to supply the Services in accordance with this agreement in all material respects. If
                    we believe your account has been compromised, we may suspend or disable it. <br />
                    3.2. AEER shall appoint a manager for the Services or other customer care solution. <br />
                    3.3. If the AEER's performance of its obligations under this agreement is prevented or delayed by any act or omission of the
                    Customer, its agents, subcontractors, consultants or employees, then, without prejudice to any other right or remedy it
                    may have, the AEER shall be allowed an extension of time to perform its obligations equal to the delay caused by the
                    Customer. <br />
                    <h3>4. CUSTOMER'S OBLIGATIONS</h3> <br />
                    4.1. The Customer shall: <br />
                    (a) pay for the paid Services; <br />
                    (b) be responsible on using account (including access credentials), provide us with complete and accurate information and
                    to keep the information current so that we can communicate with you about your account; <br />
                    (c) use the Software in accordance with the instructions from time to time; <br />
                    (d) comply with all applicable laws and regulations (including, without limitation, all applicable laws regarding receipt of
                    Service, availing us to deliver Service to you, receiving Deliverables, online conduct and acceptable content, privacy, data
                    protection, the transmission of technical data exported to/from the United States, EU/EEA, or the country in which you
                    reside, the use or provision of financial services, notification and consumer protection, unfair competition, false
                    advertising, sanctions and export control laws); <br />
                    (e) not overburden or interfere with our systems or impose an unreasonable or disproportionately large load on our
                    infrastructure, as determined by us in our sole discretion; not send spam or bulk unsolicited messages; not interfere with,
                    disrupt, or attack any service or network; not create, distribute, or enable material that is, facilitates, or operates in
                    conjunction with, malware, spyware, adware, or other malicious programs or code; not involve reverse engineering,
                    decompiling, disassembling, deciphering, or otherwise attempting to derive the source code for the Services or any
                    related technology that is not open source; <br />
                    (f) not involve renting, leasing, loaning, selling, or reselling the Services or related data without our consent. <br />
                    <h3>5. CHANGING TERMS AND PAYMENTS</h3> <br />
                    5.1. Payment processors or platforms. We use third-party payment processors to transmit your payments to us. By providing
                    a payment method to us, you agree that (i) you are authorized to use the payment method you provide; (ii) you will in
                    fact pay for the paid service by the date on which payment is due; (iii) the payment information you provide is true and
                    accurate; (iv) we may retain the payment information and method that you and the issuer of your payment method or
                    the applicable payment network submit to us; and (v) we are authorized to charge you for the paid service using the
                    payment method you present. This includes any obligation you may have to pay any taxes applicable to the service you
                    are buying. You also agree that we have permission to retain and/or share with financial institutions and our payment
                    processors (including any institutions or processors we retain in the future) information regarding your purchase and your
                    submitted payment information in order to process your purchase and to use the contact information (such as an email
                    address or phone number) submitted by you to provide you with notices and disclosures relating to renewals, recurring
                    charges, and changes affecting your purchase. <br />
                    5.2. Subscription Plan. “Charges” - the sums payable for the Services. The Customer shall pay the charges, specified in
                    accordance with the chosen Subscription Plan. Invoices and/or charges, and respective payments may be on the monthly
                    or yearly periodicity, as opted in the Subscription Plan; AEER may send invoices 5 days before the end of activated
                    month/year period. Automatic payment charges may apply if Customer opted this and provided banking details. By using
                    a Paid Service, you agree to pay the specified fees, and invoice issue is not mandatory in this case. Pricing for future
                    periods may be subject to change by us.
                    You represent and warrant to us that payment information (such as your credit card, or other payment instrument) is
                    true and that you are authorized to use the payment instrument. You will promptly update your account information with
                    any changes (for example, a change in your billing address or credit card expiration date) that may occur. In the event you
                    owe us any amounts related to your licensing of the Services, AEER reserves the right to seek collection of any amount
                    unpaid.
                    To ensure uninterrupted service, automatic payment charges are automatically renewed. This means that unless you
                    cancel a paid Service before the end of the applicable subscription period, it will automatically renew, and you authorize
                    us to use any payment information we have on record for you, or invoice you (in which case payment is due within
                    payment period indicated in invoice) to collect the then-applicable subscription fee as well as any taxes. By default, your
                    paid Services will be renewed for the same interval as your original subscription period, so for example, if you purchase a
                    one-year Subscription Plan, you’ll be charged each year for access for another 12-month period. We may charge your
                    account up to one month before the end of the subscription period to make sure billing issues do not disrupt your access
                    to our Services. The date for the automatic renewal is based on the date of the original purchase and cannot be changed.
                    If you’ve purchased access to multiple services, you may have multiple renewal dates. You are entitled to cancel auto
                    renew. <br />
                    5.3. Changes by Customer. Customer may change Subscription plan via the Profile Cabinet or in other way according to
                    available options or setting as we may introduce from time to time. In the event of changing the subscription plan, further
                    invoices and/or charges, and respective payments will be adjusted. <br />
                    5.4. Changes by AEER. AEER has the right to review the pricing with effect to the future unpaid periods (month, year, other),
                    provide discounts, special offers and best deals, as may be announced by AEER occasionally. <br />
                    5.5. Suspending service if unpaid. AEER may (but not obliged to) suspend the provision of the services if any amount due to
                    be paid by the Customer to AEER under this Agreement is overdue. However, AEER may extend service delivery waiting
                    for payment. AEER may deliver email advance notice on need to pay for the service and/or notice that payment has
                    become overdue. <br />
                    5.6. Overdue payments. If the Customer does not pay proper amount due to AEER under this Agreement, AEER may suspend
                    service. <br />
                    5.7. Invoices. AEER shall invoice the Customer for the Charges at the intervals specified in Subscription plan or this Agreement.
                    The Customer shall pay each invoice submitted to it by AEER within 5 days of receipt to a bank account nominated in
                    writing by AEER from time to time, unless longer payment period is indicated in the invoice. Invoices may contain 
                    instructions or other information regarding VAT. Invoices shall be paid in full without any set-off, counterclaim, deduction
                    or withholding (other than any deduction or withholding of tax as required by law). “VAT” - value added tax or any
                    equivalent tax chargeable in the UK or elsewhere. <br />
                    5.8. Refunds. We may have a refund policy for some of our paid Services, and we’ll also provide refunds if required by law. In
                    all other cases, there are no refunds and all payments are final. AEER may terminate your use of the Services at any time,
                    for any or no reason, and without prior notice. If AEER terminates your access to the Services for any reason other than
                    your breach of these Terms, then as your sole remedy for such termination, AEER will refund a pro rata amount of any
                    unused, prepaid fees. <br />
                    <h3>6. INTELLECTUAL PROPERTY</h3> <br />
                    6.1. “Intellectual Property Rights” - patents, rights to inventions, copyright and related rights, trade marks, business names
                    and domain names, rights in get-up, goodwill and the right to sue for passing off, rights in designs, rights in computer
                    software, database rights, rights to use, and protect the confidentiality of, confidential information (including know-how
                    and trade secrets) and all other intellectual property rights, in each case whether registered or unregistered and including
                    all applications and rights to apply for and be granted, renewals or extensions of, and rights to claim priority from, such
                    rights and all similar or equivalent rights or forms of protection which subsist or will subsist now or in the future in any
                    part of the world. <br />
                    6.2. IP is not transferred. Nothing in this Agreement shall operate to assign or transfer any Intellectual Property Rights from
                    AEER to the Customer, or from the Customer to AEER, unless otherwise is explicitly agreed upon. The access and use of
                    the Software do not constitute any ground for any intellectual property entitlement of Customer neither relating to the
                    objects created as a result of such use or access, nor to the objects already existing in the Software. <br />
                    6.3. IP restrictions. Customer does not have a right to a physical copy of the Software used. The Customer must not use the
                    Software in any way that causes, or may cause, damage to the Software (such as impairment of the availability or
                    accessibility of the Software) as well as to AEER or its clients. The Customer must not: use the Software in any way or with
                    the purpose that is unlawful, illegal, fraudulent or harmful; or allow unauthorized access of use of Software; access
                    (reverse engineer, decompile, disassemble etc.) or utilize (be it copying, amending or any other form) the code of the
                    Software (including object code, intermediate code and source code). Customer must not: republish material from the
                    Software or website (including republication on another website); sell, rent or sub-license material from the Software or
                    website; show any material from the Software or website in public; reproduce, duplicate, copy Software, or otherwise
                    exploit Software beyond acceptable use; edit or otherwise modify any material on the website; or redistribute material
                    from Software or website except for content specifically and expressly made available for redistribution. <br />
                    6.4. IP infringement remedies. AEER collects data related to the unauthorized use of its Software, including personal data.
                    Any unauthorized user, be it of Customer or any of its affiliates, of AEER's Software is hereby informed and consents to
                    such collection of data, as well as its transmission and use for anti-piracy purposes. AEER reserves its right to apply IP
                    infringement remedies worldwide. <br />
                    6.5. User Content. By sharing, submitting or uploading any of your data, feedback, suggestion, comments, or ideas (“User
                    Content”) in any way, you grant AEER a worldwide, non-exclusive, royalty-free, sub-licensable and transferable license to
                    use, reproduce, prepare derivative works of, display, and perform your User Content in any legal manner for our sole
                    benefit, including in future modifications of the Sites, other products or services, and in advertising and marketing
                    materials. You acknowledge and agree that you are solely responsible for all the User Content that you make available
                    through AEER. Accordingly, you represent and warrant that: (a) you have all rights, licenses, consents, and releases
                    necessary to grant AEER the required rights to disseminate any User Content, (b) neither your User Content nor your
                    posting, uploading, publication, submission, or transmittal of this User Content or AEER’s use of your User Content will
                    infringe, misappropriate, or violate a third party’s patent, copyright, trademark, trade secret, moral rights, or other
                    intellectual property rights or rights of publicity or privacy, or result in the violation of any applicable law or regulation. <br />
                    6.6. Customer reference. AEER reserves the right to use your name and/or company name as a reference for marketing or
                    promotional purposes on AEER’s website and in other communication with existing or potential AEER customers. To
                    decline AEER this right you need to email to us stating that you do not wish to be used as a reference. <br />
                    6.7. AEER: <br />
                    (a) warrants that the receipt, use and onward supply of the Services and the Deliverables by the Customer shall not infringe <br />
                    any rights of third parties to the extent that infringement results from copying;
                    (b) is liable subject to Limitation of liability terms; <br />
                    (c) shall not be in breach of the warranty at clause 6.7(a), and the Customer shall have no claim under the indemnity at clause
                    6.7(b), to the extent the infringement arises from: <br />
                    (i) the use of the Customer Materials in the development of, or the inclusion of the Customer Materials in any
                    Deliverable; <br />
                    (ii) any modification of the Deliverables or Services, other than by or on behalf of AEER; and
                    (iii) compliance with the Customer's specifications or instructions, where infringement could not have been avoided
                    while complying with such specifications or instructions and provided that the AEER shall notify the Customer if it
                    knows or suspects that compliance with such specification or instruction may result in infringement. <br />
                    6.8. The Customer: <br />
                    (a) warrants that the receipt and use of the Customer Materials in the performance of this agreement by AEER, its agents,
                    subcontractors or consultants shall not infringe the rights, including any Intellectual Property Rights, of any third party;
                    and 
                    (b) shall indemnify AEER in full against all liabilities, costs, expenses, damages and losses (including but not limited to any
                    direct, indirect or consequential losses, loss of profit, loss of reputation and all interest, penalties and legal costs
                    (calculated on a full indemnity basis) and all other professional costs and expenses) suffered or incurred by AEER arising
                    out of or in connection with any claim brought against AEER, its agents, subcontractors or consultants for actual or alleged
                    infringement of a third party's Intellectual Property Rights arising out of, or in connection with, the receipt or use in the
                    performance of this agreement of the Customer Materials. <br />
                    6.9. Notice and settlement <br />
                    If either party (the Indemnifying Party) is required to indemnify the other party (the Indemnified Party) under this clause,
                    the Indemnified Party shall: <br />
                    (a) notify the Indemnifying Party in writing of any Intellectual Property claim (IPRs Claim); <br />
                    (b) allow the Indemnifying Party, at its own cost, to conduct all negotiations and proceedings and to settle the IPRs Claim,
                    always provided that the Indemnifying Party shall obtain the Indemnified Party's prior approval of any settlement terms,
                    such approval not to be unreasonably withheld; <br />
                    (c) provide the Indemnifying Party with such reasonable assistance regarding the IPRs Claim as is required by the
                    Indemnifying Party, subject to reimbursement by AEER of the Indemnified Party's costs so incurred; <br /> and
                    (d) not, without prior consultation with the Indemnifying Party, make any admission relating to the IPRs Claim or attempt to
                    settle it, provided that the Indemnifying Party considers and defends any IPRs Claim diligently, using competent counsel
                    and in such a way as not to bring the reputation of the Indemnified Party into disrepute. <br />
                    <h3>7. DATA PROTECTION</h3> <br />
                    7.1. Both parties will comply with all applicable requirements of the Data Protection Legislation. <br />
                    7.2. For more rules, please refer to relevant Privacy Policy. <br />
                    <h3>8. CONFIDENTIALITY</h3> <br />
                    8.1. No party shall use any other party's confidential information for any purpose other than to exercise its rights and perform
                    its obligations under or in connection with this agreement. <br />
                    <h3>9. LIMITATION OF LIABILITY</h3> <br />
                    9.1. The liability of AEER according to the Agreement shall be limited to the one-time amount fee which was paid for last
                    activated month/year period, depending on the Subscription Plan. In no event AEER shall be liable to Customer for
                    indirect, incidental or consequential damages, including, but not limited to loss of use, loss of data, loss of production,
                    loss of interest and loss of profit, arising out of, resulting from or incident to the performance or non-performance of the
                    Services. AEER shall only be liable for any damage incurred by Customer in case of gross negligence or intent of AEER. <br />
                    9.2. AEER has given commitments as to compliance of the Services with relevant specifications in these Terms. In view of
                    these commitments, the terms implied by sections 3, 4 and 5 of the Supply of Goods and Services Act 1982 are, to the
                    fullest extent permitted by law, excluded from this agreement. <br />
                    9.3. “As is” software. AEER PROVIDES THE SOFTWARE ON THE “AS IS” AND “WITH ALL FAULTS” BASIS AND IS NOT LIABLE FOR
                    ANY DIRECT, INDIRECT, CONSEQUENTIAL, INCIDENTAL LOSES OR DAMAGES (BE IT FINANCIAL, DATA LOSES OR OTHERS)
                    EMANATING FROM THE INCORRECT WORK, ANY OTHER FAULTS OF SOFTWARE OR RESULTING FROM THE ANY
                    UNAUTHORIZED ACCESS BY THIRD PARTIES. AEER ENDEAVOURS TO PROVIDE THE BEST OF SERVICE AND MAINTAIN
                    UNRESTRICTED ACCESS TO THE SOFTWARE. AEER MAKES NO OTHER WARRANTIES, STATUTORY, EXPRESS OR IMPLIED,
                    AND SPECIFICALLY DISCLAIMS THE WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE AND
                    NONINFRINGEMENT WITH RESPECT TO THE SOFTWARE AND THE DOCUMENTATION. IN ADDITION, AEER DOES NOT
                    WARRANT THAT THE SOFTWARE’S OPERATION WILL BE UNINTERRUPTED OR ERROR-FREE, OR THAT ALL DEFECTS WILL
                    BE CORRECTED. <br />
                    9.4. Security disclaimer. The Customer acknowledges that complex Software is never entirely free from security
                    vulnerabilities; and subject to the other provisions of this Agreement, AEER gives no warranty or representation that the
                    Software will be entirely secure. AEER shall not be liable to the Customer in respect of any loss or corruption of any data,
                    database or Software providing that this Clause shall not protect AEER unless it has fully complied with its relevant
                    obligations under this Agreement. <br />
                    9.5. Notice period. Unless the Customer notifies AEER that it intends to make a claim in respect of an event within the notice
                    period, AEER shall have no liability for that event. The notice period for an event shall start on the day on which the
                    Customer became, or ought reasonably to have become, aware of its having grounds to make a claim in respect of the
                    event and shall expire 6 months from that date. The notice must be in writing and must identify the event and the grounds
                    for the claim in reasonable detail. <br />
                    9.6. The limitations and liability terms set forth above are fundamental elements of the basis of the bargain between AEER
                    and you. AEER would not be able to have provided the services without such limitations. <br />
                    <h3>10. TERMINATION</h3> <br />
                    10.1. Validity period. See above. <br />
                    10.2. Cancelling subscription by Customer. The Customer may cancel their subscription at any time and their subscription will
                    remain active for the duration of the period for which Customer had paid the services. In case of cancellation of specific
                    Subscription plan, previously paid amounts are not refundable. Cancelling of the subscription does not entail the
                    Termination of the Agreement, unless directly requested by the Customer otherwise. <br />
                    10.3. Early termination of Agreement by Customer. The Customer may terminate the agreement in whole upon 30-day
                    advance notice. Amounts paid are not refundable in case of cancellation of specific Subscription plan. <br />
                    10.4. Early termination of Agreement by AEER. AEER may terminate this Agreement by giving to the Customer at least 30 days'
                    written notice of termination. AEER must refund to the Customer any Charges paid by the Customer to AEER in respect
                    of services that were to be provided to the Customer after the termination of this Agreement, without prejudice to the
                    parties' other legal rights. <br />
                    10.5. Early termination of Agreement by AEER for breach by Customer. AEER may terminate this Agreement immediately by
                    giving email notice of termination to the Customer if the Customer commits a material breach of this Agreement. <br />
                    10.6. Termination by either Party. Subject to applicable law, either party may terminate this Agreement immediately by giving
                    written notice of termination to the other party if the other party: (1) is dissolved; (2) ceases to conduct all (or substantially
                    all) of its business; (3) is or becomes unable to pay its debts as they fall due; (4) is or becomes insolvent or is declared
                    insolvent; (5) as a result of illness or incapacity, becomes incapable of managing his or her own affairs; (6) is the subject
                    of a bankruptcy petition or order. <br />
                    10.7. Survival. <br />
                    (a) Upon the termination of this Agreement, the specific provisions of this Agreement shall survive: inter alia, related to
                    interpretation, Intellectual property rights, Confidentiality, Limitation of liability, Consequences of termination, Waiver,
                    Severability, Force-majeure, Governing law, Dispute resolution.
                    (b) Termination or expiry of this agreement shall not affect any rights, remedies, obligations or liabilities of the parties that
                    have accrued up to the date of termination or expiry. <br />
                    10.8. Consequences of termination <br />
                    On termination or expiry of this agreement:
                    (a) the Customer shall immediately pay to AEER all of AEER’s outstanding unpaid invoices and interest and, in respect of the
                    Services supplied but for which no invoice has been submitted, AEER may submit an invoice, which shall be payable
                    immediately on receipt;
                    (b) the Customer shall not use or access AEER’s software provided under this agreement;
                    (c) the specific clauses shall survive. <br />
                    <h3>11. FORCE MAJEURE</h3> <br />
                    11.1. AEER shall not be liable to the Customer in respect of any losses arising out of a Force Majeure Event. If a Force Majeure
                    Event gives rise to a failure or delay in either party performing any obligation under this Agreement (other than any
                    obligation to make a payment), that obligation will be suspended for the duration of the Force Majeure Event. Party that
                    becomes aware of a Force Majeure Event which gives rise to, or which is likely to give rise to, any failure or delay in that
                    party performing any obligation under this Agreement, must: (1) promptly notify the other; and (2) inform the other of
                    the period for which it is estimated that such failure or delay will continue. A party whose performance of its obligations
                    under this Agreement is affected by a Force Majeure Event must take reasonable steps to mitigate the effects of the Force
                    Majeure Event. <br />
                    11.2. Force Majeure Event means any circumstance not within a party's reasonable control including, without limitation: <br />
                    (a) acts of God, flood, drought, earthquake or other natural disaster; <br />
                    (b) epidemic or pandemic; <br />
                    (c) terrorist attack, civil war, civil commotion or riots, war, threat of or preparation for war, armed conflict, imposition of
                    sanctions, embargo, or breaking off of diplomatic relations; <br />
                    (d) nuclear, chemical or biological contamination or sonic boom; <br />
                    (e) any law or any action taken by a government or public authority, including without limitation imposing an export or
                    import restriction, quota or prohibition, or failing to grant a necessary license or consent; <br />
                    (f) collapse of buildings, fire, explosion or accident; <br /> and
                    (g) interruption or failure of utility service. <br />
                    11.3. Provided it has complied with clause 11.1-11.2, if a party is prevented, hindered or delayed in or from performing any of
                    its obligations under this agreement by a Force Majeure Event (Affected Party), the Affected Party shall not be in breach
                    of this agreement or otherwise liable for any such failure or delay in the performance of such obligations. The time for
                    performance of such obligations shall be extended accordingly. The Affected Party shall:
                    (a) as soon as reasonably practicable after the start of the Force Majeure Event but no later than 30 days from its start, notify
                    the other party in writing of the Force Majeure Event, the date on which it started, its likely or potential duration, and the
                    effect of the Force Majeure Event on its ability to perform any of its obligations under the agreement; and
                    (b) use all reasonable endeavors to mitigate the effect of the Force Majeure Event on the performance of its obligations. <br />
                    11.4. If the Force Majeure Event prevents, hinders or delays the Affected Party's performance of its obligations for a continuous
                    period of more than 6 weeks, the party not affected by the Force Majeure Event may terminate this agreement by giving
                    4 weeks' written notice to the Affected Party. <br />
                    <h3>12. MISCELLANEOUS</h3> <br />
                    <b>12.1. Severability.</b> If any provision of this Agreement is determined by any court or other competent authority to be unlawful
                    and/or unenforceable, the other provisions of this Agreement will continue in effect. If any unlawful and/or
                    unenforceable provision would be lawful or enforceable if part of it were deleted, that part will be deemed to be deleted,
                    and the rest of the provision will continue in effect (unless that would contradict the clear intention of the parties, in
                    which case the entirety of the relevant provision will be deemed to be deleted). <br />
                    <b>12.2. Assignment.</b> AEER may transfer, sub-contract or otherwise deal with its rights and/or obligations under these terms and
                    conditions without notifying Customer or obtaining Customer’s consent. Customer may not transfer, sub-contract or
                    otherwise deal with Customer’s rights and/or obligations under these terms and conditions. <br />
                    <b>12.3. Governing law.</b> This Agreement will be governed exclusively by and construed in accordance with the laws of USA. <br />
                    <b>12.4. Interpretation rules.</b> A person includes a natural person, corporate or unincorporated body (whether or not having
                    separate legal personality). A reference to a company shall include any company, corporation or other body corporate,
                    wherever and however incorporated or established. Unless the context otherwise requires, words in the singular shall
                    include the plural and in the plural shall include the singular. Unless the context otherwise requires, a reference to one
                    gender shall include a reference to the other genders. Any words following the terms including, include, in particular, for
                    example or any similar expression shall be construed as illustrative and shall not limit the sense of the words, description,
                    definition, phrase or term preceding those terms. These Terms and the Contract are made only in the English language.
                    This agreement shall be binding on the parties to this agreement and their respective personal representatives, successors
                    and permitted assigns, and references to any party shall include that party's personal representatives, successors and
                    permitted assigns.
                    A reference to a statute or statutory provision is a reference to it as amended, extended or re-enacted from time to time.
                    A reference to a statute or statutory provision shall include all subordinate legislation made from time to time under that
                    statute or statutory provision. A reference to writing or written includes fax and email.
                    Any obligation on a party not to do something includes an obligation not to allow that thing to be done. A reference to
                    this agreement or to any other agreement or document referred to in this agreement is a reference of this agreement or
                    such other agreement or document as varied or novated (in each case, other than in breach of the provisions of this
                    agreement) from time to time. “Applicable Laws” - all applicable laws, statutes, regulations from time to time in force. <br />
                    <b>12.5. Waiver.</b> A waiver of any right or remedy under this agreement or by law is only effective if given in writing and shall not
                    be deemed a waiver of any subsequent right or remedy. A failure or delay by a party to exercise any right or remedy
                    provided under this agreement or by law shall not constitute a waiver of that or any other right or remedy, nor shall it
                    prevent or restrict any further exercise of that or any other right or remedy. No single or partial exercise of any right or
                    remedy provided under this agreement or by law shall prevent or restrict the further exercise of that or any other right
                    or remedy. <br />
                    <b>12.6. Rights and remedies.</b> The rights and remedies provided under this agreement are in addition to, and not exclusive of, any
                    rights or remedies provided by law. <br />
                    <b>12.7. Conflict.</b> If there is an inconsistency between any of the provisions of these Terms and the provisions of the Schedules
                    thereto, the provisions of these Terms shall prevail. <br />
                    <b>12.8. No partnership or agency.</b> Nothing in this agreement is intended to, or shall be deemed to, establish any partnership or
                    joint venture between any of the parties, constitute any party the agent of another party, or authorize any party to make
                    or enter into any commitments for or on behalf of any other party. Each party confirms it is acting on its own behalf and
                    not for the benefit of any other person. <br />
                    <b>12.9. Third party rights.</b> The rights of the parties to rescind or vary this agreement are not subject to the consent of any other
                    person. <br />
                    <b>12.10. Notices.</b> Any notice or other communication given to a party under or in connection with this agreement shall be in writing
                    and shall be:
                    (a) delivered by hand or by pre-paid first-class post or other next working day delivery service at its registered office (if a
                    company) or its principal place of business (in any other case); or
                    (b) sent by fax to its main fax number or sent by email to the address known to each party respectively.
                    Any notice or communication shall be deemed to have been received:
                    (a) if delivered by hand, on signature of a delivery receipt;
                    (b) if sent by pre-paid first-class post or other next working day delivery service, at 9.00 am on the second Business Day after
                    posting; and
                    (c) if sent by fax or email, at the time of transmission, or, if this time falls outside business hours in the place of receipt, when
                    business hours resume. Business hours means 9.00am to 5.00pm Monday to Friday on a day that is not a public holiday
                    in the place of receipt.
                    This clause does not apply to the service of any proceedings or any documents in any legal action or, where applicable,
                    any arbitration or other method of dispute resolution.
                    “Business Day” - a day, other than a Saturday, Sunday or public holiday in the United States, when banks in the State of
                    Delaware are open for business. “Business Hours” - the period from 9.00 am to 5.00 pm on any Business Day (time zone
                    is UTC). <br />
                    <b>12.11. Counterparts</b> <br />
                    (a) This agreement may be executed in any number of counterparts, each of which when executed shall constitute a duplicate
                    original, but all the counterparts shall together constitute the one agreement. <br />
                    (b) Transmission of an executed counterpart of this agreement (but for the avoidance of doubt not just a signature page) by
                    (a) fax or (b) email (in PDF, JPEG or other agreed format) shall take effect as delivery of an executed counterpart of this
                    agreement. If either method of delivery is adopted, without prejudice to the validity of the agreement thus made, each
                    party shall provide the other with the original of such counterpart as soon as reasonably possible thereafter. <br />
                    <b>12.12. Dispute resolution.</b> The Parties shall strive to settle any conflict which may arise through dialog and mutual discussions.
                    In case of any conflicting situation, the Parties shall inform one another about the matter of conflict in a timely manner.
                    The Party, which was informed about contradictory circumstances strives to settle the issue on the good faith principles.
                    The Parties may engage the neutral certified mediator as to resolve the matter from agreed independent local or global
                    mediation body. <br />
                    <h3>Schedule 1. Services Details</h3>
                    <b>1. Services:</b> Providing an unlimited paid use to AEER’s software available for download at Google Play and App Store (or any other
                    method, which will be notified to the Customer in advance). <br />
                    <b>2. Deliverables:</b> The Customer will be able to use software features, make notes and create User Content, create tasks and track
                    their completion. The Customer acknowledges that these features are for information purposes only and the Customer shall not
                    rely on, or make any decisions based on, the information provided by such services.
                    Schedule 2. Charges, costs and payment
                    Charges. The Customer shall notify AEER in advance which of AEER plan will apply to the provision of the Services, in particular: <br />
                    <b>“Basic Plan” - Monthly plan:</b> <br />
                    The total monthly charges for the Services are: USD 4,99 per one calendar month. AEER shall invoice the Customer within 10 days
                    in advance for the Services to be performed during calendar month, which starts on the date when the Customer paid for the
                    Services in full for such calendar month. The Customer shall pay each invoice submitted to it by the AEER within 3 days of receipt. <br />
                    <b>“Standard Plan” - Monthly plan:</b> <br />
                    The total monthly charges for the Services are: USD 9,99 per one calendar month. AEER shall invoice the Customer within 10 days
                    in advance for the Services to be performed during calendar month, which starts on the date when the Customer paid for the
                    Services in full for such calendar month. The Customer shall pay each invoice submitted to it by AEER within 3 days of receipt. <br />
                    <b>“Premium Plan” - Monthly plan:</b> <br />
                    The total monthly charges for the Services are: USD 29.99 per one calendar month. AEER shall invoice the Customer within 10 days
                    in advance for the Services to be performed during calendar month, which starts on the date when the Customer paid for the
                    Services in full for such calendar month. The Customer shall pay each invoice submitted to it by AEER within 3 days of receipt.
                    <br /><br />
                    <h3>13. Purchases and Subscriptions</h3> <br />
                    1. If you wish to purchase any product or service made available through Service (“Purchase”),  you may be asked to supply certain information relevant to your Purchase including, without  limitation, your credit card number, the expiration date of your credit card, your billing address,  and your shipping information. <br />
                    You represent and warrant that: (i) you have the legal right to use any credit card(s) or other  payment method(s) in connection with any Purchase; and that (ii) the information you supply  to us is true, correct and complete.<br />
                    We may employ the use of third party services for the purpose of facilitating payment and the  completion of Purchases. By submitting your information, you grant us the right to provide the  information to these third parties subject to our Privacy Policy.<br />
                    We reserve the right to refuse or cancel your order at any time for reasons including but not  limited to: product or service availability, errors in the description or price of the product or  service, error in your order or other reasons.<br />
                    We reserve the right to refuse or cancel your order if fraud or an unauthorized or illegal  transaction is suspected.   <br /><br />
                    2. <b>Contests, Sweepstakes and Promotions</b> <br />
                    Any contests, sweepstakes or other promotions (collectively, <b>“Promotions”</b>) made available  through Service may be governed by rules that are separate from these Terms of Service. If  you participate in any Promotions, please review the applicable rules as well as our Privacy  Policy. If the rules for a Promotion conflict with these Terms of Service, Promotion rules will  apply. <br /><br />
                    3. <b>Subscriptions </b><br />
                    Some parts of Service are billed on a subscription basis (<b>“Subscription(s)”</b>). You will be billed  in advance on a recurring and periodic basis (<b>“Billing Cycle”</b>). Billing cycles are set either on  a monthly or annual basis, depending on the type of subscription plan you select when  purchasing a Subscription.
                    At the end of each Billing Cycle, your Subscription will automatically renew under the exact  same conditions unless you cancel it or AEER Platform Inc. cancels it. You may cancel your  Subscription renewal either through your online account management page or by  contacting AEER Platform Inc, Inc. customer support team.
                    A valid payment method, including credit card or PayPal, is required to process the payment  for your subscription. You shall provide AEER Platform Inc, Inc. with accurate and complete billing  information including full name, address, state, zip code, telephone number, and a valid  payment method information. By submitting such payment information, you automatically  authorize AEER Platform Inc, Inc. to charge all Subscription fees incurred through your account to any such  payment instruments.
                    Should automatic billing fail to occur for any reason, AEER Platform Inc will issue an electronic invoice  indicating that you must proceed manually, within a certain deadline date, with the full payment  corresponding to the billing period as indicated on the invoice.<br /><br />
                    4. <b>Free Trial</b>  <br />
                    AEER Platform Inc may, at its sole discretion, offer a Subscription with a free trial for a limited period  of time (<b>“Free Trial”</b>).
                    You may be required to enter your billing information in order to sign up for Free Trial.
                    If you do enter your billing information when signing up for Free Trial, you will not be charged  by AEER Platform Inc. until Free Trial has expired. On the last day of Free Trial period, unless you  cancelled your Subscription, you will be automatically charged the applicable Subscription  fees for the type of Subscription you have selected.
                    At any time and without notice, AEER Platform, Inc. reserves the right to (i) modify Terms of Service of  Free Trial offer, or (ii) cancel such Free Trial offer. <br /><br />
                    5. <b>Fee Changes</b>  <br />
                    AEER Platform, Inc., in its sole discretion and at any time, may modify Subscription fees for the  Subscriptions. Any Subscription fee change will become effective at the end of the then current Billing Cycle. <br />
                    AEER Platform, Inc. will provide you with a reasonable prior notice of any change in Subscription fees  to give you an opportunity to terminate your Subscription before such change becomes  effective. Your continued use of Service after Subscription fee change comes into effect constitutes  your agreement to pay the modified Subscription fee amount. <br /><br />
                    6. <b>Refunds</b> <br />
                    Except when required by law, paid Subscription fees are non-refundable.
                </div>	
            </div>
            <MainFooter />
        </>
    )
}

export default Privacy;

export const Head: HeadFC = () => (
    <>
        <title>Terms of User - AEER PLATFORM</title>
        <meta name="viewport" content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no"/>
    </>
);
